import { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from './utils'

/*-----------------------------------*/
/* Global Type
/*-----------------------------------*/

export const navigation = css`
    ${tw`text-red`};
    font-family: 'GTF Riposte Thin';
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 0.04em;

    ${media.mob`
        font-size: 16px;
    `}
`

export const heading = css`
    ${tw`text-red`};
    font-family: 'Granville Light';
    font-size: 24px;
    line-height: 0.79;
    letter-spacing: 0.04em;

    ${media.mob`
        font-size: 20px;
    `}
`

export const body = css`
    ${tw`text-red`};
    font-family: 'GTF Riposte Thin';
    font-size: 17px;
    line-height: 1.764705882em;
    letter-spacing: 0.04em;

    ${media.mob`
        font-size: 16px;
    `}
`

export const caption = css`
    ${tw`text-red`};
    font-family: 'GTF Riposte Thin';
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.04em;

    ${media.mob`
        font-size: 16px;
    `}
`
