import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { motion } from 'framer-motion'
import { isMobile } from 'react-device-detect'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useLocation, useWindowScroll } from 'react-use'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { navigation } from '../../styles/type'
import { parseACF, renderLink } from '../../utils'

import ih_logo from '../../assets/images/island-house-logo.svg'

export const headerHeight = 150

const Header = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressGlobal')
    const [subMenu, setSubmenu] = useState(false)
    const [headerCollapsed, setHeaderCollapsed] = useState(false)
    const subMenuEl = useRef(null)
    const location = useLocation()
    const {y} = useWindowScroll()
    // console.log(data)

    useEffect(() => {
        if (isMobile) {
            if (subMenu) {
                disableBodyScroll(subMenuEl.current)
            } else {
                clearAllBodyScrollLocks()
            }
        }
    }, [subMenu])

    useEffect(() => {
        const offset = 100
        if (y > offset) {
            if (!headerCollapsed) {
                setHeaderCollapsed(true)
            }
        } else {
            if (headerCollapsed) {
                setHeaderCollapsed(false)
            }
        }
    }, [y]);

    useEffect(() => {
        setSubmenu(false)
    }, [location])

    const renderNavItems = items => {
        if (Array.isArray(items)) {
            return items.map((item, i) => {
                if (item.type === 'link' && item.link.type === 'internal') {
                    return (
                        <NavItem key={i} to={item.link.url}>
                            {item.link_text}
                        </NavItem>
                    )
                } else {
                    return renderLink(item, i)
                }
            })
        } else {
            if (items.type === 'link' && items.link.type === 'internal') {
                return <NavItem to={items.link.url}>{items.link_text}</NavItem>
            } else {
                return renderLink(items, 0)
            }
        }
    }

    const fadeVariants = {
        hidden: {
            opacity: 0,
            transitionEnd: {
                display: 'none',
            },
        },
        visible: {
            display: 'flex',
            opacity: 1,
        },
    }

    const renderMobileContactInfo = () => {
        if (!data.mobile_contact_info) return
        return (
            <Info
                dangerouslySetInnerHTML={{
                    __html: data?.mobile_contact_info,
                }}
            />
        )
    }

    const renderNavLeft = () => {
        return (
            <Left>
                <Nav className="nav-left">
                    <div className="desktop">
                        {renderNavItems(data.nav_left_top)}
                    </div>
                    {data?.include_dropdown_menu_left && (
                        <SubmenuWrapper
                            onMouseEnter={() => !isMobile && setSubmenu(true)}
                            onMouseLeave={() => !isMobile && setSubmenu(false)}
                        >
                            <NavToggle onClick={() => setSubmenu(!subMenu)}>
                                <div className="desktop">
                                    {data.dropdown_menu_text ? data.dropdown_menu_text : 'Menu'}
                                </div>
                                <div className="mobile">
                                    Menu
                                </div>
                            </NavToggle>
                            <Submenu
                                ref={subMenuEl}
                                initial={'hidden'}
                                animate={subMenu ? 'visible' : 'hidden'}
                                variants={fadeVariants}
                            >
                                <div class="desktop">
                                    {renderNavItems(data.nav_left)}
                                </div>
                                <div class="mobile">
                                    <NavToggle
                                        onClick={() => setSubmenu(false)}
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Back
                                    </NavToggle>
                                    {renderNavItems(data.mobile_nav)}
                                    {renderMobileContactInfo()}
                                </div>
                            </Submenu>
                        </SubmenuWrapper>
                    )}
                </Nav>
            </Left>
        )
    }

    const renderNavRight = () => {
        return (
            <Right>
                <Nav>
                    <div class="desktop">
                        {renderNavItems(data.nav_right)}
                    </div>
                    <div class="mobile">
                        {renderNavItems(data.mobile_nav_right)}
                    </div>
                </Nav>
            </Right>
        )
    }

    const variants = {
        notCollapsed: {
            height: `${headerHeight}px`
        },
        collapsed: {
            height: `100px`
        }
    }

    return (
        <Wrapper>
            <Container>
                <Grid
                    initial={'notCollapsed'}
                    animate={!isMobile && headerCollapsed ? "collapsed" : "notCollapsed"}
                    variants={variants}
                    transition={{ duration: 0.3, ease: 'linear' }}
                >
                    {data.nav_left && renderNavLeft()}
                    <HomeLink to={`/`}>
                        <Logo src={ih_logo} />
                    </HomeLink>
                    {data.nav_right && renderNavRight()}
                </Grid>
            </Container>
        </Wrapper>
    )
}

// Shared

const Wrapper = styled.div`
    ${tw`z-10 fixed top-0 inset-x-0 py-8 bg-beige`};

    ${media.mob`
        ${tw`pt-4 pb-0`};
    `}
`

const Container = styled.div`
    ${tw`relative`};
    ${container}
    ${padding}
`

// Layout

const Left = styled.div``
const Right = styled.div``
const Grid = styled(motion.div)`
    height: ${headerHeight}px;
    ${tw`flex justify-between items-center w-full`};

    ${media.mob`
        height: 136px!important;
        ${tw`items-end`};

        > div {
            ${tw`mb-4 px-8 text-center`};
        }
    `}
`

// Navigation (Left and Right)

const NavToggle = styled.div`
    .desktop {
        ${tw`flex`};
        ${media.mob`
            display: none;
        `}
    }
    .mobile {
        display: none;
        ${media.mob`
            ${tw`flex`};
        `}
    }
`
const NavItem = styled(Link)``
const Nav = styled.div`
    ${tw`relative z-20 flex flex-row justify-center text-center`};
    min-width: 200px;

    ${media.mob`
        min-width: initial;
    `}

    a,
    ${NavToggle},
    ${NavItem} {
        ${tw`inline-block px-4`};
        text-decoration: none;
        ${navigation};
        cursor: pointer;

        ${media.mob`
            ${tw`px-0`};
        `}
    }

    > .desktop {
        ${tw`flex`};
        ${media.mob`
            display: none;
        `}
    }

    > .mobile {
        display: none;

        ${media.mob`
            ${tw`flex`};
        `}
    }
`

// Submenu

const Info = styled.div``
const Submenu = styled(motion.div)``
const SubmenuWrapper = styled.div`
    position: relative;
    ${Submenu} {
        display: none;
        ${tw`z-20 absolute whitespace-no-wrap py-4 bg-beige`};
        top: 100%;
        left: 50%;
        transform: translateX(-50%);

        ${media.mob`
            ${tw`fixed inset-0 whitespace-normal bg-red`};
            ${padding}
            overflow-y: auto;
            transform: none;
        `}

        .desktop {
            ${tw`flex flex-col w-full`};
            ${media.mob`
                display: none;
            `}
        }

        .mobile {
            display: none;
            ${media.mob`
                ${tw`flex flex-col w-full`};
            `}
        }

        a,
        ${NavToggle}, ${NavItem} {
            ${tw`inline-block py-3 px-4`};

            ${media.mob`
                ${tw`text-white`};
            `}
        }

        ${NavToggle} {
            ${tw`pt-4 pb-10`};
        }

        /* mobile contact info */
        ${Info} {
            ${tw`flex flex-col mt-6 pt-8 border-t`};
            border-color: rgba(255, 255, 255, 0.5);

            &,
            * {
                ${navigation};
                ${tw`text-white`};
            }
        }
    }
`

// Logo

const Logo = styled.img``
const HomeLink = styled(Link)`
    ${tw`absolute inset-y-0 inline-block h-full`};
    left: 50%;
    transform: translateX(-50%);

    ${media.mob`
        width: 150px;
    `}

    ${Logo} {
        ${tw`h-full w-auto`};

        ${media.mob`
            ${tw`w-full h-auto`};
        `}
    }
`

export const query = graphql`
    query {
        allWordpressGlobal {
            nodes {
                acf_json
            }
        }
    }
`

export default Header
