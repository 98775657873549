import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState } from '../../../styles/global'
import { body } from '../../../styles/type'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { useMount } from 'react-use'

const BlockTypeForm = props => {
    const { typeform_url } = props
    // console.log(props)

    useMount(() => {
        // prevent iframe from auto scrolling the page on load
        var el = document.querySelector('.ReactTypeformEmbed iframe')

        if (el) {
            el.style.display = 'none'

            el.onload = function() {
                el.style.display = 'block'
            }
        }
    })
 
    return (
        <Wrapper>
            <Container>
                {typeform_url && (
                    <ReactTypeformEmbed url={typeform_url} style={{ position: 'static', height: '100vh' }}/>
                )}
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div``

const Container = styled.div`
    ${container};
	${padding};
`

export default BlockTypeForm
