import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'
import tw from 'tailwind.macro'

export const maxWidth = 1440

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        ${tw`m-0 p-0 w-full h-full leading-none bg-beige`};
        font-family: 'GTF Riposte Thin', Arial, Helvetica, sans-serif;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
    }
    
    a {
        text-decoration: underline;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
    }

    b, strong {
        font-weight: 700;
    }

    p:not(:first-child) {
        ${tw`mt-4`};
    }

    ul {
        list-style: disc;
        padding-left: 2rem;
    }
 
    * {
        -webkit-overflow-scrolling: touch;
    }
`

export const container = css`
    ${tw`w-full mx-auto`};
    max-width: ${maxWidth}px;
`

export const padding = css`
    ${tw`px-16`};
    box-sizing: border-box;

    ${media.tabPor`
        ${tw`px-6`};
    `}
`

export const hoverState = css`
    opacity: 1;
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const button = css`
    ${tw`inline-block py-2 px-6 leading-none border bg-white border-black text-black`};
    font-weight: 400;
    font-size: 15px;
    letter-spacing: -0.02em;
    ${hoverState};
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`
