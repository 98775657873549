import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'
import Slider from 'react-slick'

import { media } from '../../../styles/utils'
import { container, padding, bgImage, hoverState, maxWidth } from '../../../styles/global'
import { caption } from '../../../styles/type'

import arrow from '../../../assets/icons/arrow-nav.png'

const BlockImageSlider = props => {
    const { slider } = props
    const sliderRef = useRef()
    // console.log(props)

    const settings = {
        dots: true,
        arrows: false,
        speed: 800,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }

    const next = function() {
        if (!sliderRef || !sliderRef.current) return
        sliderRef.current.slickNext()
    }

    const renderSlides = () => {
        return slider.map((item, i) => {
            return (
                <Slide key={i} full_width={props?.full_width}>
                    <SlideWrap onClick={() => next()}>
                        <Image key={item.image.id} src={item.image.url}>
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    </SlideWrap>
                    {item.caption && (
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: item.caption,
                            }}
                        />
                    )}
                </Slide>
            )
        })
    }

    return (
        <Wrapper>
            <Container full_width={props?.full_width}>
                <Slider ref={sliderRef} {...settings} className={props?.full_width ? 'full-width' : ''}>
                    {renderSlides()}
                </Slider>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div``

const Container = styled.div`
    ${tw`relative`};
    ${container};
    ${padding};
    max-width: 1000px;

    ${props => {
        if (props.full_width)
            return css`
                max-width: ${maxWidth}px;
            `
    }}

    ${media.mob`
        ${tw`px-0`};
    `}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Text = styled.div`
    ${tw`w-1/2 mt-10`};
    ${caption};

    ${media.mob`
        ${tw`mt-6 px-6`};
        box-sizing: content-box;
    `}
`

const SlideWrap = styled.div`
    cursor: url(${arrow}), auto;
`
const Slide = styled.div`
    ${tw`w-full outline-none`}

    ${SlideWrap} {
        ${tw`w-full`};
        height: 600px;

        ${props => {
            if (props.full_width)
                return css`
                    height: 800px;
                `
        }}

        ${media.mob`
            height: 400px;
            ${tw`px-6`};
        `}
    }

    ${Image}, ${LoadedImage} {
        ${tw`w-full h-full`};
        object-fit: contain;
        object-position: center;

        ${props => {
            if (props.full_width)
                return css`
                    object-fit: cover;
                `
        }}

        ${media.mob`
            object-fit: cover;
        `}
    }
`

export default BlockImageSlider
