import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'
import { useToggle } from 'react-use'
import { motion, AnimatePresence } from 'framer-motion'

import { media } from '../../../styles/utils'
import { container, padding, hoverState } from '../../../styles/global'
import { heading, body } from '../../../styles/type'

const BlockTextExpandable = props => {
    const [expanded, toggleExpanded] = useToggle(false)
    // console.log(props)

    const renderImage = () => {
        return (
            <ImageWrapper>
                <Image key={props?.image?.id} src={props?.image?.url}>
                    {(src, loading) => {
                        return (
                            <LoadedImage
                                src={src}
                                style={{ opacity: loading ? 0 : 1 }}
                            />
                        )
                    }}
                </Image>
            </ImageWrapper>
        )
    }

    return (
        <Wrapper>
            <Container image_position={props?.image_position}>
                <Grid image_position={props?.image_position}>
                    {props?.include_image && props?.image && renderImage()}
                    <TextWrapper>
                        {props?.heading && (
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: props?.heading,
                                }}
                            />
                        )}
                        {props?.text && (
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: props?.text,
                                }}
                            />
                        )}

                        <AnimatePresence>
                            {expanded && props?.expandable_text && (
                                <motion.div
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    <Info
                                        dangerouslySetInnerHTML={{
                                            __html: props?.expandable_text,
                                        }}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>

                        {props?.expandable_text && (
                            <Toggle onClick={() => toggleExpanded()}>{props?.expand_link_text ? props.expand_link_text : 'Learn More'}</Toggle>
                        )}
                    </TextWrapper>
                </Grid>
            </Container>
        </Wrapper>
    )
}

const Image = styled(ProgressiveImage)``

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div``

const Container = styled.div`
    ${container};
    ${padding};

    ${props => {
        if (props.image_position === 'top')
            return css`
                max-width: 820px;
            `
    }}
`

const Grid = styled.div`
    ${tw`flex flex-col w-full`};

    > * {
        ${tw`w-full`};
    }

    ${props => {
        if (props.image_position === 'left')
            return css`
                ${tw`flex-row`};

                ${media.mob`
                    ${tw`flex-col`};
                `}

                > * {
                    ${tw`w-1/2`};

                    ${media.mob`
                        ${tw`w-full`};
                    `}
                }

                > *:last-child {
                    padding-left: 4rem;

                    ${media.mob`
                        ${tw`pl-0`};
                    `}
                }
            `
    }}
`

const ImageWrapper = styled.div`
    ${tw`mx-auto mb-20 overflow-hidden`};
    width: 400px;
    height: 400px;
    max-width: 100%;

    ${Image}, ${LoadedImage} {
        ${tw`w-full h-full`};
        object-fit: cover;
        object-position: center;
    }
`

const TextWrapper = styled.div`
    ${tw`flex flex-col items-center`};
`

const Heading = styled.div`
    ${tw`mb-16 uppercase text-center`};
    ${heading};
`

const Text = styled.div`
    ${body};
`

const Info = styled.div`
    ${tw`mt-16`};
    ${body};
    font-style: italic;
`

const Toggle = styled.div`
    ${tw`inline-block mx-auto mt-16 text-center cursor-pointer border-b border-red`};
    ${heading};
    font-size: 20px;
`

export default BlockTextExpandable
