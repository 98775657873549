import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState } from '../../../styles/global'
import { body } from '../../../styles/type'

const BlockTextSingle = props => {
    const { text, text_1, text_2, include_image, image } = props
    // console.log(props)

    return (
        <Wrapper>
            <Container>
                {include_image && image && (
                    <Image key={image?.id} src={image?.sizes?.large}>
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
                {text && (
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                )}
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div``

const Container = styled.div`
    ${container};
    ${padding};
    max-width: 820px;
`

const Text = styled.div`
    ${body};
`

const Image = styled(ProgressiveImage)``

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;

    ${tw`mx-auto mb-20`};
`

export default BlockTextSingle
