import React from 'react'
import Transition from './src/transition'
import { GlobalStyles } from './src/styles/global'
import { Header } from './src/components'

export default ({ element, props }) => {
    return (
        <>
            <GlobalStyles />
            <Header />
            {element}
        </>
    )
}
