import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'

import { MetaTags } from '../'
import { media } from '../../styles/utils'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../styles/slick.css'

import favicon from '../../assets/icons/islandhouse-favicon.png'

const Layout = ({ children, meta }) => {
	return (
		<>
			<Helmet>
				<link rel="icon" href={favicon} type="image/png" />
			</Helmet>

			<MetaTags
				{...meta}
			/>
			<Wrapper>
				{children}
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	box-sizing: border-box;
`

export default Layout
