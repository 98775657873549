import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding } from '../../../styles/global'
import { heading, body } from '../../../styles/type'

const BlockHeading = props => {
    const { heading, text } = props
    // console.log(props)

    return (
        <Wrapper>
            <Container>
                {heading && (
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: heading,
                        }}
                    />
                )}
                {text && (
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                )}
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${media.mob`
        ${tw`px-6`};
    `}
`

const Container = styled.div`
    ${tw`flex flex-col items-center py-32 border-t border-b border-red`};
    ${container};
    ${padding};
    max-width: 950px;

    ${media.mob`
        ${tw`py-24 px-0`};
    `}
`

const Heading = styled.div`
    ${tw`text-center uppercase`};
    ${heading};
    max-width: 700px;
`

const Text = styled.div`
    ${tw`mt-12 text-justify`};
    ${body};
    max-width: 700px;
`

export default BlockHeading
