import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState } from '../../../styles/global'
import { caption } from '../../../styles/type'

const BlockImageSingle = props => {
    const { forceLandscape, image, caption } = props
    // console.log(props)

    return (
        <Wrapper>
            <Container
                width={image?.width}
                height={image?.height}
            >
                {image && !forceLandscape && (
                    <Image key={image?.id} src={image?.sizes?.large}>
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
                {image && forceLandscape && (
                    <ImageWrapper>
                        <Image key={image?.id} src={image?.url}>
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    </ImageWrapper>
                )}
                {caption && (
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: caption,
                        }}
                    />
                )}
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div``

const ImageWrapper = styled.div``

const Image = styled(ProgressiveImage)``

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Text = styled.div`
    ${tw`mt-4`};
    ${caption};
`

const Container = styled.div`
    ${container};
    ${padding};
    max-width: 820px;

    ${Image}, ${LoadedImage} {
        width: 475px;
        ${tw`mx-auto`};

        ${props => {
            if (props.width > props.height)
                return css`
                    width: 100%;
                `
        }}
    }

    /* force landscape is true */
    ${ImageWrapper} {
        ${tw`relative w-full overflow-hidden`};
        height: 0;
        padding-bottom: 66.62%;

        ${Image}, ${LoadedImage} {
            ${tw`absolute inset-0 w-full h-full`};
            object-fit: cover;
            object-position: center;
        }
    }
`

export default BlockImageSingle
