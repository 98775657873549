import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState } from '../../../styles/global'
import { heading, body } from '../../../styles/type'

const BlockMap = props => {
    // console.log(props)

    const renderMap = () => {
        return (
            <Map>
                <Container>
                    <Image key={props?.map?.id} src={props?.map?.url}>
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                </Container>
            </Map>
        )
    }

    const renderInfoList = () => {
        const items = props.info_list.map((item, i) => {
            return (
                <Item key={i}>
                    <Heading dangerouslySetInnerHTML={{
                        __html: item.heading,
                    }}/>
                    <Description dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}/>
                </Item>
            )
        })

        return (
            <Items>
                <Container>
                    {items}
                </Container>
            </Items>
        )
    }

    return (
        <Wrapper>
            {props?.heading && (
                <Top>
                    <Container>
                        <Heading dangerouslySetInnerHTML={{
                            __html: props?.heading,
                        }}/>
                    </Container>
                </Top>
            )}
            {props?.map && renderMap()}
            {props?.info_list && renderInfoList()}
        </Wrapper>
    )
}

const Heading = styled.div`
    ${tw`text-center uppercase`};
    ${heading}
`
const Description = styled.div`${body}`


const Image = styled(ProgressiveImage)``

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div``

const Container = styled.div`
    ${container};
    ${padding};
`

// Top Heading
const Top = styled.div`
    ${Container} {
        max-width: 950px;
        ${tw`relative pt-32`};

        ${media.mob`
            ${tw`pt-24`};
        `}

        &::before {
            content: '';
            ${tw`absolute top-0 inset-x-0 w-full bg-red`};
            height: 1px;
        }
    }

    ${Heading} {
        ${tw`mb-16`};
    }
`

// Map image
const Map = styled.div`
    ${Image}, ${LoadedImage} {
        width: 1172px;
        max-width: 100%;
        ${tw`mx-auto`};
    }
`

// Info List
const Item = styled.div``
const Items = styled.div`

    ${Container} {
        max-width: 820px;
    }
    
    ${Item} {
        ${tw`mt-20`};

        ${Heading} {
            ${tw`mb-16`};

            ${media.mob`
                ${tw`mb-12`};
            `}
        }
        ${Description} {}
    }
`

export default BlockMap
