import React from 'react'
import { forEach } from 'lodash'
import { useStaticQuery } from 'gatsby'
import { default as FadeAnimation } from 'react-reveal/Fade'

export const parseACF = (queryData, nodeName, debug) => {
    if (queryData && queryData[nodeName]) {
        const nodes = queryData[nodeName].nodes
        const acfJSON = nodes && nodes[0].acf_json

        if (!acfJSON) return

        // Console log out if debug = true

        debug && console.log(JSON.parse(acfJSON))

        // Return parsed ACF JSON

        return JSON.parse(acfJSON)
    }
}

export const parseACFMultiple = (queryData, nodeName) => {
    if (queryData && queryData[nodeName]) {
        const nodes = queryData[nodeName].nodes

        const items = nodes.map((item, i) => {
            const acfJSON = item.acf_json
            if (!acfJSON) return
            return {...item, ...JSON.parse(item.acf_json)}
        })

        // Return parsed ACF JSON array
        return items
    }
}

// Group option page keys by prefix

export const groupByPrefix = (data, prefix) => {
    let items = {}

    forEach(data, (item, key) => {
        if (key.includes(prefix)) {
            items[key.replace(`${prefix}_`, '')] = item
        }
    })

    return items
}

// Get global data

export const getGlobalData = prefix => {
    const query = graphql`
        query {
            allWordpressGlobal {
                nodes {
                    acf_json
                }
            }
        }
    `

    // Fetch global data

    const globalData = parseACF(useStaticQuery(query), 'allWordpressGlobal')

    // Return global + grouped by prefix

    return {
        global: globalData,
        data: groupByPrefix(globalData, prefix),
    }
}

// Fade Animation

export const Fade = ({ children, ...restProps }) => {
    return (
        <FadeAnimation bottom distance={'40px'} {...restProps}>
            {children}
        </FadeAnimation>
    )
}

// Render Link

export const renderLink = (link, i) => {
    switch (link.type) {
        case 'link':
            if (link.link.type === 'internal') {
                return (
                    <a
                        key={i}
                        onClick={e => {
                            event.preventDefault()
                            navigate(`${link.link.url}`)
                        }}
                    >
                        {link.link_text}
                    </a>
                )
            } else {
                return (
                    <a key={i} href={`${link.link.url}`} target={`_blank`}>
                        {link.link_text}
                    </a>
                )
            }
            break

        case 'file':
            return (
                <a key={i} href={`${link.file}`} target={`_blank`}>
                    {link.link_text}
                </a>
            )
            break

        case 'email':
            return (
                <a key={i} href={`mailto:${link.email}`}>
                    {link.link_text}
                </a>
            )
            break

        default:
            return <></>
    }
}