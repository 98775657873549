import { 
    BlockHeading,
    BlockTextSingle,
    BlockTextDoubleWithHeading,
    BlockTextExpandable,
    BlockImageFull,
    BlockImageSingle,
    BlockImageDouble,
    BlockImageSlider,
    BlockMap,
    BlockJournalGrid,
    BlockTypeForm,
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'bordered_text_with_heading': BlockHeading,
        'single_column_text': BlockTextSingle,
        'two_column_text_with_heading': BlockTextDoubleWithHeading,
        'expandable_text': BlockTextExpandable,
        'full_width_image': BlockImageFull,
        'single_image': BlockImageSingle,
        'double_image': BlockImageDouble,
        'image_slider': BlockImageSlider,
        'map': BlockMap,
        'journal_grid': BlockJournalGrid,
        'typeform_embed': BlockTypeForm
    }
    
    return blocks[layout]
}


