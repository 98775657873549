import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'

import { media } from '../../../styles/utils'
import { container, padding, hoverState } from '../../../styles/global'
import { heading, body } from '../../../styles/type'
import { parseACFMultiple } from '../../../utils'

const BlockJournalGrid = props => {
    const data = parseACFMultiple(useStaticQuery(query), 'allWordpressJournal')
    // console.log(data)

    const renderItems = () => {
        const items = data.map((item, i) => {
            return (
                <Item key={i}>
                    {item.thumbnail_image && (
                        <ImageLink to={`/journal/${item.slug}`}>
                            <Image
                                key={item.thumbnail_image.id}
                                src={item.thumbnail_image.url}
                            >
                                {(src, loading) => {
                                    return (
                                        <LoadedImage
                                            src={src}
                                            style={{ opacity: loading ? 0 : 1 }}
                                        />
                                    )
                                }}
                            </Image>
                        </ImageLink>
                    )}
                    {item.title && (
                        <TitleLink to={`/journal/${item.slug}`}>
                            {`Entry No.${i+1}:`}<br/>
                            {item.title}
                        </TitleLink>
                    )}

                    {item.slug && (
                        <ReadMoreLink to={`/journal/${item.slug}`}>
                            Read more
                        </ReadMoreLink>
                    )}
                </Item>
            )
        })

        return <Items>{items}</Items>
    }

    return (
        <Wrapper>
            <Container>{data && renderItems()}</Container>
        </Wrapper>
    )
}

const Image = styled(ProgressiveImage)``

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div``

const Container = styled.div`
    ${container};
    ${padding};
    ${tw`-mb-32`};

    ${media.mob`
        ${tw`-mb-16`};
    `}
`

const ImageLink = styled(Link)``
const TitleLink = styled(Link)``
const ReadMoreLink = styled(Link)``
const Item = styled.div``
const Items = styled.div`
    ${tw`flex flex-wrap justify-center w-full`};

    ${media.mob`
        ${tw`block`};
    `}

    ${Item} {
        ${tw`flex flex-col items-center w-1/2 mb-32 px-16 text-center`};

        ${media.tabPor`
            ${tw`px-6`};
        `}

        ${media.mob`
            ${tw`w-full px-0 mb-16`};
        `}
    }

    ${ImageLink} {
        ${tw`w-full mb-20 overflow-hidden`};
        height: 500px;

        ${media.mob`
            ${tw`mb-12`};
            height: 400px;
        `}

        ${Image}, ${LoadedImage} {
            ${tw`w-full h-full`};
            object-fit: cover;
            object-position: center;
        }
    }

    ${TitleLink} {
        max-width: 320px;
        ${tw`uppercase`};
        text-decoration: none;
        ${heading};
        line-height: 1.3;
    }

    ${ReadMoreLink} {
        ${tw`mt-6`};
        ${body};
        text-decoration: none;

        ${media.mob`
            ${tw`mt-4`};
        `}
    }
`

export const query = graphql`
    query {
        allWordpressJournal {
            nodes {
                slug
                title
                acf_json
            }
        }
    }
`

export default BlockJournalGrid
