import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'
import Div100vh from 'react-div-100vh'

import { media, useBreakpoint } from '../../../styles/utils'
import { headerHeight } from '../../Header/Header'

const BlockImage = props => {
    const {
        index,
        image,
        use_side_margins,
        use_different_image_on_mobile,
        mobile_image,
    } = props
    // console.log(props)

    return (
        <>
            <Wrapper use_side_margin={use_side_margins} index={index}>
                {image && (
                    <ImageWrapper>
                        <Image key={image.id} src={image.url}>
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    </ImageWrapper>
                )}
            </Wrapper>
            {index === 0 && (
                <FullHeight>
                    {image && (
                        <Image
                            key={
                                use_different_image_on_mobile
                                    ? mobile_image?.url
                                    : image?.url
                            }
                            src={
                                use_different_image_on_mobile
                                    ? mobile_image?.url
                                    : image?.url
                            }
                        >
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                    )}
                </FullHeight>
            )}
        </>
    )
}

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const FullHeight = styled(Div100vh)`
    ${tw`px-6 pb-12`};
    padding-top: 160px;
    display: none;
    ${media.mob`
        display: block;
    `}

    ${Image}, ${LoadedImage} {
        ${tw`w-full h-full`};
        object-fit: cover;
        object-position: center;
    }
`

const ImageWrapper = styled.div``
const Wrapper = styled.div`
    ${tw`relative mx-auto py-32`};
    max-width: 1600px;

    ${media.mob`
        ${tw`py-12`};
        height: 400px;
    `}

    ${props => {
        if (props.index === 0)
            return css`
                padding-top: ${headerHeight + 64}px;

                ${media.mob`
                    display: none;
                `}
            `
    }}

    ${props => {
        if (props.use_side_margin)
            return css`
                ${tw`px-6`};
            `
    }}

    ${ImageWrapper} {
        ${tw`relative w-full`};
        height: 0;
        padding-bottom: 45%;
        overflow: hidden;

        ${media.mob`
            height: 100%;
            padding-bottom: 0;
        `}
    }

    ${Image}, ${LoadedImage} {
        ${tw`absolute inset-0 w-full h-full`};
        object-fit: cover;
        object-position: center;
    }
`

export default BlockImage
