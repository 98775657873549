import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState } from '../../../styles/global'
import { body, heading } from '../../../styles/type'

const BlockTextDoubleWithHeading = props => {
    // const { text_1, text_2 } = props
    // console.log(props)

    return (
        <Wrapper>
            <Container>
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: props?.heading,
                    }}
                />
                <Grid>
                    <Left>
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: props?.text_1,
                            }}
                        />
                    </Left>
                    <Right>
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: props?.text_2,
                            }}
                        />
                    </Right>
                </Grid>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div``

const Container = styled.div`
    ${container};
    ${padding};
    max-width: 1240px;
`

const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex -mx-16`};

    ${media.mob`
        ${tw`block mx-0`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2 px-16`};
        
        ${media.mob`
            ${tw`w-full px-0`};
        `}
    }

    ${Right} {
        ${media.mob`
            ${tw`mt-16`};
        `}
    }
`

const Heading = styled.div`
    ${tw`uppercase text-center mb-32`};
    ${heading};

    ${media.mob`
        ${tw`mb-16`};
    `}
`

const Text = styled.div`
    ${body};
`

export default BlockTextDoubleWithHeading
