import React from 'react'
import { resolveBlock } from './utils'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { Fade } from '../../utils'
import { media } from '../../styles/utils'
import { headerHeight } from '../../components/Header/Header'

const Block = props => {
    const { layout, hide_on_mobile, index } = props
    const BlockLayout = resolveBlock(layout)
    if (!BlockLayout) return null

    // fade delay
    let delay
    switch (props?.index) {
        case 0:
            delay = 800
            break
        case 1:
            delay = 800
            break
        default:
            delay = 0
            break
    }

    return (
        <Wrapper
            className={`block_${layout}`}
            layout={layout}
            isArticle={props.isArticle ? true : false}
            hideOnMobile={hide_on_mobile}
        >
            <Fade key={props?.index} delay={delay}>
                {BlockLayout && <BlockLayout {...props} />}
            </Fade>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    ${props => {
        if (props.layout !== 'full_width_image')
            return css`
                ${tw`py-32`};

                ${media.mob`
                  ${tw`py-16`};
                `}

                /* first block */
                &:first-child {
                    padding-top: ${headerHeight + 128}px;

                    ${media.mob`
                        padding-top: 160px;
                    `}
                }
            `
    }}

    ${props => {
        if (!props.isArticle && props.layout === 'bordered_text_with_heading')
            return css`
                /* second block on mobile*/
                &:nth-child(2) {
                    ${media.mob`
                        ${tw`-mt-6`};
                        padding-top: 0;
                    `}
                }
            `
    }}

    /* last block */
    &:last-child {
        ${tw`pb-64`};

        ${media.mob`
            ${tw`pb-32`};
        `}
    }

    ${props => {
        if (props.hideOnMobile)
            return css`
                ${media.mob`
                    display: none;
                `}
            `
    }}
`

export default Block
