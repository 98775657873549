import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { media } from '../../../styles/utils'
import { container, padding, hoverState } from '../../../styles/global'
import { caption } from '../../../styles/type'

const BlockImageDouble = props => {
    // console.log(props)

    const renderImage = image => {
        if (!image) return
        return (
            <Image key={image?.id} src={image?.url}>
                {(src, loading) => {
                    return (
                        <LoadedImage
                            src={src}
                            style={{ opacity: loading ? 0 : 1 }}
                        />
                    )
                }}
            </Image>
        )
    }

    return (
        <Wrapper>
            <Container>
                <Grid>
                    <Left>
                        {renderImage(props?.image_1)}
                    </Left>
                    <Right>
                        {renderImage(props?.image_2)}
                    </Right>
                </Grid>
            </Container>
        </Wrapper>
    )
}

const Image = styled(ProgressiveImage)``

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div``

const Container = styled.div`
    ${container};
    ${padding};
`

const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex`};

    ${media.mob`
        ${tw`flex-col`};
    `}

    ${Left}, ${Right} {
        ${tw`w-1/2`};

        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${Left} {
        padding-right: 9%;

        ${media.mob`
            ${tw`pr-0`};
            ${tw`mb-20`};
        `}
    }

    ${Right} {
        padding-left: 9%;

        ${media.mob`
            ${tw`pl-0`};  
        `}
    }

    ${Image}, ${LoadedImage} {
        ${tw`w-full h-auto`};
    } 
`



export default BlockImageDouble
